import Page from '../../page'
import MainSearchWithResultsWidget from '../../../../../widgets/main_search_with_results/script'
import StateNearbyCorporationsWidget from '../../../../../widgets/state/nearby_corporations/script'

export default class StatePage extends Page {
  constructor() {
    super()

    this.mainSearchWithResultsWidget = new MainSearchWithResultsWidget(false, 24)
    this.corporationsSlider = new StateNearbyCorporationsWidget()
  }
}
