import Page from '../../page'
import MainSearchWithResultsWidget from '../../../../../widgets/main_search_with_results/script'
import CorporationsCarouselWidget from '../../../../../widgets/corporations/carousel/script'
import ShowMore from '../../../lib/show_more'
import ViewportHelper from '../../../lib/viewport_helper'

export default class CityPage extends Page {
  constructor() {
    super()
    this.mainSearchWithResultsWidget = new MainSearchWithResultsWidget(false, 24, false)
    this.corporationCarouselWidget = new CorporationsCarouselWidget()
    this.showMore = new ShowMore(ViewportHelper.getInstance())
  }
}
